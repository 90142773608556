import LocalForage from 'localforage'

// Configure localforage
LocalForage.config({
    driver: LocalForage.IndexedDB,
    name: location.hostname,
    version: 1.0,
    storeName: 'vuex-store'
});

export default {
    timelimit: 30,
    getTimeStamp() {
        return LocalForage.getItem('timestamp').then(timestamp => {
            return timestamp
        })
    },
    setTimeStamp(timestamp) {
        LocalForage.setItem('timestamp', timestamp)
    },
    init(store) {
        return this.getTimeStamp().then(timestamp => {
            const now = new Date();
            if (timestamp)
                return LocalForage.getItem('state').then((savedState) => {
                    if (savedState) {
                        if (timestamp.getDate() <= now.getDate()){
                            if(new Date((now - timestamp)).getMinutes() <= this.timelimit){
                                store.replaceState(savedState)
                                return
                            }
                        }
                        
                        this.setTimeStamp(now)
                    }
                })

            this.setTimeStamp(now)
        })

    },
    delete() {
        return LocalForage.removeItem('state')
    },
    update: store => {
        const LF = LocalForage
        store.subscribe((mutations, state) => {
            LF.setItem('state', state, () => {
                //Data saved
            })
        })
    }
}