// import currencies from '../vue-components/assets/currencies'
import PersistPlugin from './plugins/persist'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const setNestedKey = (obj, path, value) => {
    if (path.length === 1) {
        obj[path] = value;
        return
    }
    return setNestedKey(obj[path[0]], path.slice(1), value)
};

const store =  new Vuex.Store({
    plugins:[
        PersistPlugin.update
    ],
    state: {
        items: {},
        filters: {},
        latest_filter:'',
        isLoading: {
            is: false,
            uid: 15,
        },
        fetching:false,
        preserve: false,
        steps: ['list', 'detail', 'final'],
        purchased: false,
        view: 'list'
    },
    mutations: {
        set(state, {state_key, key, value}) {
            if (!state[state_key].hasOwnProperty(key))
                Vue.set(state[state_key], key, value);
            else
                state[state_key][key] = value;
        },
        putInFilters(state, {key, value}) {
            this.commit('set', {state_key: 'filters', key, value})
        },
        putInItems(state, {key, value}) {
            this.commit('set', {state_key: 'items', key, value})
        },
        setLoading(state, payload) {
            state.isLoading = payload
        },
        setFetching(state, is) {
            state.fetching = is
        },
        setCurrentStep(state, step){
            state.view = step;
        },
        setSettlement(state, is) {
            state.settlement = is
        },
        setFiltered(state, is) {
            state.filtered = is
        },
        setPreserve(state, is) {
            state.preserve = is
        },
        removeFilterByName(state, key) {
            Vue.delete(state.filters, key)
        },
        removeAllFilters(state) {
            Vue.set(state, 'filters', {})
        },
        removeItemByName(state, key) {
            Vue.delete(state.items, key)
        }
    },
    actions: {
        init(){
            return PersistPlugin.init(this)
        },
        get({commit}, payload = { url, params:{} }){
            const {url, params} = payload
            return axios.get(url, {
                params
            }).then(({data}) => {
                return data
            }).catch(e => console.log(e))
        },
        fetch({commit, dispatch}, {name, url}) {
            return new Promise((resolve, reject) => {
                dispatch('get',{ url }).then(({data}) => {
                    if(data){
                        commit('putInItems', {
                            key: name,
                            value: data
                        });
                        resolve(data)
                    }

                    reject('Error en la sincronización')
                }).catch((e) => {
                    console.log(e);
                    reject(e)
                })
            })
        }
    },
    getters: {
        getFilterByName: (state) => (key, def = '') => {
            return state.filters[key] || def
        },
        getItemsByName: (state) => (key, def = []) => {
            return state.items[key] || def
        },
        isPreserved: state => {
            return state.preserve;
        },
        isFetching: state => {
            return state.fetching;
        },
        isLoading: state => (ref) => {
            return state.isLoading.uid === ref._uid && state.isLoading.is
        },
        getAllFilters: state => () => {
            return state.filters;
        },
        step: state => {
            return state.view
        },
        steps: state => {
            return state.steps
        },
        cart: (state, getters) => {
            return getters.getItemsByName('cart', {
                items: [],
                totales: 0,
                qty: 0
            })
        },
        user: (state, getters) => {
            return getters.getItemsByName('user', {})
        }
    }
})

export default store

