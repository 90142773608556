export default {
    es: {
        custom: {
            nombre: {
                required: 'Por favor introduzca su nombre'
            },
            apellido: {
                required: 'Por favor introduzca su apellido'
            },
            direccion: {
                required: 'Por favor introduzca su dirección particular'
            },
            ciudad: {
                required: 'Por favor introduzca la ciudad en que se localiza'
            },
            departamento: {
                required: 'Por favor introduzca el departamento en donde radica'
            },
            codigo_postal: {
                required: 'Por favor introduzca su código postal'
            },
            envio_departamento: {
                required: 'Por favor introduzca el departamento destinado a los envíos'
            },
            envio_ciudad: {
                required: 'Por favor introduzca la ciudad destinada a los envíos'
            },
            envio_direccion: {
                required: 'Por favor introduzca la dirección destinada a los envíos'
            },
            envio_codigo_postal: {
                required: 'Por favor introduzca el código postal localizado para los envíos'
            },
            email: {
                required: 'Por favor introduzca su correo eléctronico',
                email: 'La dirección de correo entrada no es válida'
            },
            cedula: {
                required: 'Por favor introduzca su cédula',
                regex:'El formato es incorrecto, la sintaxis debe ser: #.###.###-#'
            },
            asunto: {
                required: 'Por favor introduzca el asunto de su mensaje',
            },
            consulta: {
                required: 'Por favor introduzca su consulta',
            },
            telefono: {
                required: 'Por favor introduzca su número de teléfono',
                numeric: 'El número teléfonico debe estar compuesto sólo por números'
            },
            celular: {
                required: 'Por favor introduzca su número de celular',
                regex:'El formato es incorrecto, la sintaxis debe ser: 09# ### ###'
            },
            password:{
                required: 'Por favor introduzca una contraseña',
                min: 'Su contraseña debe tener un mínimo de 8 caracteres'
            },
            confirm:{
                required: 'Por favor introduzca una contraseña de confirmación',
                confirmed:'Las contraseñas no coinciden'
            }
        },
        attributes: {
            nombre: 'nombre',
            email: 'correo',
            query: 'consulta',
            telefono: 'teléfono',
        },
        required: 'requerido'
    }
};
